import React, { useContext } from 'react';
import * as S from './ProductItemV2.styles';
import { formatPrice } from '../../utils/formatPrice';
import FunnelContext from '../../context/FunnelContext';

const ProductItemV2 = props => {
  const { currency } = useContext(FunnelContext);
  const { image, displayTitle, price, quantity, containerName } = props;
  const total = price * quantity;

  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  return (
    <S.Container>
      <S.ProductWrapper>
        <S.ProductImage>
          {image && <img src={image} alt={displayTitle} />}
          <S.QuantityCircle>{quantity}</S.QuantityCircle>
        </S.ProductImage>
        <S.ProductInfo>
          <S.Text>{displayTitle}</S.Text>
          <S.Text>{formatPrice(total, priceSettings)}</S.Text>
          <S.Text>
            {quantity} × {containerName}
          </S.Text>
        </S.ProductInfo>
      </S.ProductWrapper>
    </S.Container>
  );
};

export default ProductItemV2;
