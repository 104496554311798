import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import ProductItemV2 from '../ProductItem/ProductItemV2';
import { ProductPrice } from '../../utils/productPrice';
import { useCount } from '../../context/CountContext';

const Receipt = () => {
  const { state } = useCount();
  const { repurchaseQuantity: quantity } = state;
  const {
    onetime,
    subscription,
    currentCategory,
    currentProduct,
    bumpOfferChecked,
    bumpOfferIndex
  } = useContext(FunnelContext);
  const isSubscription = currentCategory === 'subscriptions';
  const { products, bumpoffers } = isSubscription ? subscription : onetime;
  const product = products.find((el, i) =>
    isSubscription
      ? currentProduct === `subscription_${i}`
      : currentProduct === `onetime_${i}`
  );
  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  return (
    <>
      <ProductItemV2
        image={product?.checkoutData?.image}
        displayTitle={product.display_title}
        price={discountedPrice}
        quantity={quantity}
        containerName={product?.productRef?.containerName || 'item'}
      />
      {bumpOfferChecked && (
        <ProductItemV2
          image={bumpoffers[bumpOfferIndex]?.checkoutData?.image}
          displayTitle={bumpoffers[bumpOfferIndex].display_title}
          price={bumpoffers[bumpOfferIndex]?.checkoutData?.discounted_price}
          quantity={1}
          containerName={
            bumpoffers[bumpOfferIndex]?.productRef?.containerName || 'item'
          }
        />
      )}
    </>
  );
};

export default Receipt;
